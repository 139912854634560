<template>
  <div>
    <TabTitle class="mb-4" icon="fa-file-invoice" action-position="right">
      <template>Invoices</template>
      <!-- <template #sub-title>Validated invoices relating to this account.</template> -->
      <template #actions>
        <div class="d-flex justify-content-end align-items-center">
          <input v-model="search" class="form-control form-control-search input-sm" :style="{ width: '500px' }" placeholder="Search invoices..." />
          <button
            v-if="$permissions.write('account', selectedAccount)"
            data-cy="add-new-btn"
            class="btn btn-primary ml-3"
            @click.prevent="showInvoiceForm = !showInvoiceForm"
          >
            <i class="fas fa-fw fa-plus"></i> New Invoice
          </button>
        </div>
      </template>
    </TabTitle>

    <Spinner v-if="loadingAction.accountInvoices" class="mb-5">Loading invoices...</Spinner>

    <div v-if="showInvoiceForm" class="block">
      <div class="block-content bg-body-light">
        <InvoiceCreateForm :account="selectedAccount" @completed="refreshInvoices" />
      </div>
    </div>

    <div v-if="!loadingAction.accountInvoices && sortedInvoices.length > 0" id="accordion" role="tablist">
      <div v-for="invoice in sortedInvoices" :key="invoice._id" class="block block-rounded mb-4">
        <div :id="`accordion_h${invoice._id}`" class="block-header block-header-default" :class="{ 'is-simulated': invoice.simulated }" role="tab">
          <div class="d-flex justify-content-between align-items-center">
            <a
              class="font-w600 collapsed"
              data-toggle="collapse"
              data-parent="#accordion"
              :href="`#accordion_a${invoice._id}`"
              @click="edit === invoice._id ? (edit = false) : (edit = invoice._id)"
            >
              <span v-if="invoice.values">
                <div class="mb-1">{{ invoice.values.startDate | date }} - {{ invoice.values.endDate | date }}</div>
                <div class="small">
                  <span class="mr-4"
                    ><i class="fa fa-meter fa-fw mr-1"></i
                    >{{ selectedAccount.type !== 'water' ? invoice.values.totalUnits : invoice.values.totalWaterVolume | numberFormat(2) }}</span
                  >
                  <span v-if="!$auth.settings.hideCostData" class="mr-4"
                    ><i class="fa fa-file-invoice-dollar fa-fw mr-1"></i> £{{ invoice.values.netTotalCost | numberFormat(2) }}</span
                  >
                  <span
                    v-if="
                      !isNaN(invoice.values.netTotalCost) &&
                      !isNaN(invoice.values[selectedAccount.type !== 'water' ? 'totalUnits' : 'totalWaterVolume']) &&
                      !$auth.settings.hideCostData
                    "
                    class="mr-4"
                    ><i class="fa fa-coin fa-fw mr-1"></i>
                    {{
                      invoice.values[selectedAccount.type !== 'water' ? 'totalUnits' : 'totalWaterVolume'] === 0
                        ? 0
                        : ((invoice.values.netTotalCost * 100) / invoice.values[selectedAccount.type !== 'water' ? 'totalUnits' : 'totalWaterVolume'])
                          | numberFormat(2)
                    }}p / unit</span
                  >
                  <ReadType :invoice="invoice" />
                </div>
              </span>
              <span v-else class="text-danger"><i class="fa fa-ban mr-2" /> Invoice has no values</span>
            </a>
          </div>
          <div class="d-flex align-items-center">
            <span v-if="loading.paid[invoice._id]"><i class="fa fa-spinner fa-spin mr-2"></i></span>
            <div
              v-if="$permissions.write('account', selectedAccount)"
              class="custom-control custom-checkbox custom-control-inline"
              :class="{ 'custom-control-success': invoice.isPaid }"
            >
              <input
                :id="`invoice-paid-${invoice._id}`"
                type="checkbox"
                class="custom-control-input"
                :disabled="loading.paid[invoice._id]"
                :name="`invoice-paid-${invoice._id}`"
                :checked="invoice.isPaid"
                @input="e => onClickPaid(invoice, e.target.checked)"
              />

              <label class="custom-control-label" :class="{ 'text-success': invoice.isPaid }" :for="`invoice-paid-${invoice._id}`">Paid</label>
            </div>
            <button v-if="$permissions.write('account', selectedAccount)" class="btn btn-link font-w600" @click.prevent="modals.creditNote = invoice">
              <i class="fa fa-fw fa-coins"></i> Credit Note
            </button>
            <button class="btn btn-primary-inline" @click="modals.move = invoice"><i class="fas fa-arrow-up"></i> Move</button>
            <button v-if="invoice.s3Key" class="btn font-w600" @click.prevent="() => onClickDownloadInvoice(invoice)">
              <i class="fa fa-fw fa-file-pdf"></i> {{ invoiceLoadingAction.downloadInvoice === invoice._id ? 'Loading...' : 'PDF' }}
            </button>
            <button v-else class="btn font-w600" disabled><i class="fa fa-fw fa-file-pdf"></i> PDF</button>

            <button
              v-if="$permissions.write('account', selectedAccount)"
              class="btn text-danger"
              :disabled="loadingAction.deleteAccountInvoice"
              @click.prevent="() => onClickDeleteInvoice(invoice)"
            >
              <i class="fa fa-fw fa-trash"></i> {{ loadingAction.deleteAccountInvoice === invoice._id ? 'Deleting...' : 'Delete' }}
            </button>
          </div>
        </div>
        <div v-if="edit === invoice._id" :id="`accordion_a${invoice._id}`" class="collapse" role="tabpanel" data-parent="#accordion">
          <div class="block-content">
            <InvoiceResults :key="invoice._id" :loading="loadingAction.accountInvoices" :invoice="invoice" @change="onInvoiceChange" />
          </div>
        </div>
      </div>
    </div>
    <div v-else-if="!loadingAction.accountInvoices" class="alert alert-warning" role="alert">
      <p class="mb-0">
        {{ accountInvoices.length > 0 ? 'No invoices found.' : 'No invoices uploaded.' }}
      </p>
    </div>

    <div v-if="$permissions.write('account', selectedAccount) && deletedInvoices.length > 0">
      <h4 class="mb-0"><i class="fa fa-fw fa-ban text-danger" /> Deleted Invoices</h4>
      <p>These invoices have been deleted in the past. You can restore them by clicking on the Restore button.</p>
      <a v-if="!showDeleted" class="font-w600" href="#" @click.prevent="showDeleted = true"
        ><div class="block block-rounded bg-light text-center p-3">Show deleted invoices</div></a
      >

      <div v-if="showDeleted">
        <div v-for="invoice in deletedInvoices" :key="invoice._id" class="block block-rounded mb-4">
          <div :id="`accordion_h${invoice._id}`" class="block-header block-header-default" role="tab">
            <div class="d-flex justify-content-between align-items-center">
              <a class="font-w600 collapsed" data-toggle="collapse" data-parent="#accordion" :href="`#accordion_a${invoice._id}`">
                <span v-if="invoice.values">
                  <span class="mr-4">{{ invoice.values.startDate | date }} - {{ invoice.values.endDate | date }}</span>
                  <span v-if="'totalUnits' in invoice.values && selectedAccount.type !== 'water'" class="mr-4">{{ invoice.values.totalUnits }}</span>
                  <span v-if="'totalWaterVolume' in invoice.values && selectedAccount.type === 'water'" class="mr-4">{{
                    invoice.values.totalWaterVolume
                  }}</span>
                  <span v-if="!$auth.settings.hideCostData" class="mr-4">£{{ invoice.values.netTotalCost | round(2) }}</span>
                  <ReadType :invoice="invoice" />
                  <span class="badge badge-danger ml-2">DELETED</span>
                </span>
                <span v-else class="text-danger"><i class="fa fa-ban mr-2" /> Invoice has no values</span>
              </a>
            </div>
            <div class="d-flex align-items-center">
              <button
                v-if="$permissions.write('account', selectedAccount)"
                class="btn text-warning"
                :disabled="loadingAction.restoreInvoice"
                @click.prevent="() => onClickRestoreInvoice(invoice)"
              >
                <i class="fa fa-fw fa-arrow-rotate-left"></i> {{ loadingAction.restoreInvoice ? 'Deleting...' : 'Restore' }}
              </button>
            </div>
          </div>
          <div :id="`accordion_a${invoice._id}`" class="collapse" role="tabpanel" data-parent="#accordion">
            <div class="block-content">
              <InvoiceResults :key="invoice._id" :loading="loadingAction.accountInvoices" :invoice="invoice" @change="onInvoiceChange" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <ConfirmModal :open="!!modals.pdf" title="PDF Download" @close="modals.pdf = false">
      <div class="d-flex mb-3">Choose which PDF you would like to download</div>

      <template v-slot:footer>
        <button class="btn btn-sm btn-light" type="button" :disabled="loading.main" @click.prevent="modals.pdf = false">Close</button>
        <button
          type="button"
          class="btn btn-sm btn-primary"
          data-bs-dismiss="modal"
          :disabled="!modals.pdf?.downloadUrls?.validationSheetUrl"
          @click="downloadInvoice('validationSheetUrl')"
        >
          <i class="fa fa-file-invoice fa-fw"></i> Validation Sheet
        </button>
        <button
          type="button"
          class="btn btn-sm btn-primary"
          data-bs-dismiss="modal"
          :disabled="!modals.pdf?.downloadUrls?.stampedUrl"
          @click="downloadInvoice('stampedUrl')"
        >
          <i class="fa fa-stamp fa-fw"></i> Stamped
        </button>
        <button type="button" class="btn btn-sm btn-primary" data-bs-dismiss="modal" @click="downloadInvoice('url')">
          <i class="fa fa-file fa-fw"></i> Original
        </button>
      </template>
    </ConfirmModal>
    <ConfirmModal
      :open="!!modals.remove"
      title="Delete Invoice"
      :text="modals.remove ? `Please confirm you would like to remove invoice number: <strong>${modals.remove.values?.invoiceNumber}</strong>` : null"
      @close="modals.remove = false"
      @submit="onDeleteInvoice"
    />
    <ConfirmModal
      :open="!!modals.creditNote"
      title="Move Invoice to Credit Note"
      prevent
      :loading="accountLoadingAction.convertInvoiceToCreditNote"
      @close="modals.creditNote = false"
      @submit="onClickMoveToCreditNote"
    />
    <ConfirmModal :open="!!modals.move" title="Move Invoice" prevent @close="modals.move = false" @submit="onMove">
      <LookaheadSearch
        v-if="!loading.main"
        :key="modals.move"
        ref="lookaheadComponent"
        :action="(query, params) => onSearchAccounts(query, params)"
        :results="accountResults"
        :loading="accountLoadingAction.search"
        :title="'Account'"
        @select="account => onSelectAccount(account)"
      >
        <template v-slot:result="slotProps">
          <div class="d-flex justify-content-between align-items-center">
            <div class="ml-3">
              <div class="mr-2">
                <strong>{{ slotProps.data.name }}</strong>
              </div>
              <span class="badge badge-info text-capitalize mr-2">
                {{ slotProps.data.type }}
              </span>

              <span v-if="slotProps.data.supplier" class="badge badge-info text-capitalize mr-2">
                {{ slotProps.data.supplier.name }}
              </span>
              <div class="mr-2">
                <span v-if="slotProps.data.asset" class="badge badge-primary text-capitalize mr-2">
                  {{ slotProps.data.asset.siteName }}
                </span>
              </div>
            </div>
          </div>
        </template>
      </LookaheadSearch>
      <div v-else class="d-flex justify-content-center">
        <Spinner v-if="loading.main">Updating {{ title }}..</Spinner>
      </div>
    </ConfirmModal>
  </div>
</template>
<script>
import { friendlyInvoiceName } from '../../../lib/helpers.js';
import slugify from 'slugify';
import { mapActions, mapGetters, mapMutations } from 'vuex';

import ConfirmModal from '@/components/ConfirmModal';
import InvoiceCreateForm from '@/components/InvoiceCreateForm';
import InvoiceResults from '@/components/InvoiceResults';
import LookaheadSearch from '@/components/LookaheadSearch';
import ReadType from '@/components/ReadType';
import Spinner from '@/components/Spinner';
import TabTitle from '@/components/base/TabTitle';

export default {
  name: 'AssetAccountInvoices',
  components: {
    TabTitle,
    ConfirmModal,
    InvoiceResults,
    ReadType,
    LookaheadSearch,
    Spinner,
    InvoiceCreateForm
  },
  data() {
    return {
      modals: {
        remove: false,
        move: null,
        creditNote: false,
        pdf: false
      },
      showInvoiceForm: false,
      search: '',
      loading: {
        paid: {},
        main: false
      },
      showDeleted: false,
      moveToSelectedAccount: null,
      edit: false
    };
  },
  computed: {
    ...mapGetters({
      asset: 'asset/asset',
      accounts: 'asset/accounts',
      loadingAction: 'asset/loadingAction',
      invoiceLoadingAction: 'invoice/loadingAction',
      distributors: 'supplier/distributors',
      accountLoadingAction: 'account/loadingAction',
      accountError: 'account/errorAction',
      accountInvoices: 'asset/accountInvoices',
      selectedAccount: 'asset/selectedAccount',
      accountResults: 'account/searchResults'
    }),
    mpanBreakdown() {
      return this.selectedAccount.meterPointNumber
        ? [
            this.selectedAccount.meterPointNumber.slice(0, 2),
            this.selectedAccount.meterPointNumber.slice(2, 5),
            this.selectedAccount.meterPointNumber.slice(5, 8),
            this.selectedAccount.meterPointNumber.slice(8, 10),
            this.selectedAccount.meterPointNumber.slice(10, 14),
            this.selectedAccount.meterPointNumber.slice(14, 18),
            this.selectedAccount.meterPointNumber.slice(18, 21)
          ]
        : ['', '', '', '', '', '', ''];
    },
    sortedInvoices() {
      let sorted = this.accountInvoices || [];

      sorted = sorted.filter(invoice => !invoice.deleted && (!invoice.values || Object.keys(invoice.values).length > 0));

      if (this.search) {
        sorted = sorted.filter(
          invoice =>
            invoice.values &&
            (invoice.values.meterPointNumber?.includes(this.search) ||
              invoice.values.meterSerialNumber?.includes(this.search) ||
              invoice.values.supplierRef?.includes(this.search) ||
              invoice.values.invoiceNumber?.includes(this.search))
        );
      }

      sorted.sort((a, b) => (a.values && b.values ? new Date(b.values.startDate) - new Date(a.values.startDate) : 0));

      return sorted;
    },
    deletedInvoices() {
      let sorted = this.accountInvoices || [];

      sorted = sorted.filter(invoice => invoice.deleted && (!invoice.values || Object.keys(invoice.values).length > 0));

      if (this.search) {
        sorted = sorted.filter(
          invoice =>
            invoice.values &&
            (invoice.values.meterPointNumber.includes(this.search) ||
              invoice.values.meterSerialNumber.includes(this.search) ||
              invoice.values.supplierRef.includes(this.search))
        );
      }

      sorted.sort((a, b) => (a.values && b.values ? new Date(b.values.startDate) - new Date(a.values.startDate) : 0));

      return sorted;
    },
    distributor() {
      if (this.selectedAccount.type !== 'electricity') return null;
      if (!this.selectedAccount.meterPointNumber) return null;
      const distributorId = this.selectedAccount.meterPointNumber.slice(8, 10);
      return this.distributors.find(d => d.identifier === distributorId);
    }
  },
  methods: {
    ...mapActions({
      getAccounts: 'asset/accounts',
      updateAccount: 'account/update',
      uploadInvoice: 'account/uploadInvoice',
      getDownloadUrls: 'invoice/download',
      updateInvoice: 'asset/updateAccountInvoice',
      deleteInvoice: 'asset/deleteAccountInvoice',
      restoreInvoice: 'asset/restoreAccountInvoice',
      searchAccounts: 'account/search',
      getInvoicesByAccount: 'asset/getInvoicesByAccount',
      convertInvoiceToCreditNote: 'account/convertInvoiceToCreditNote',
      createStampedInvoice: 'invoice/createStampedInvoice'
    }),
    ...mapMutations({
      manualUpdateInvoice: 'asset/UPDATE_ACCOUNT_INVOICE_SUCCESS'
    }),
    refreshInvoices() {
      this.getInvoicesByAccount({ id: this.$route.params.accountId, data: { params: { $deleted: true } } });
      this.showInvoiceForm = false;
    },
    onClickCreditNote(invoice) {
      this.modals.creditNote = invoice;
    },
    async onSelectAccount(account) {
      this.moveToSelectedAccount = account;
    },
    async onSearchAccounts(query, params) {
      return await this.searchAccounts({ query, params, companyId: this.$auth.companyId });
    },
    async onMove() {
      if (this.moveToSelectedAccount) {
        this.loading.main = true;

        const update = await this.updateInvoice({
          id: this.modals.move._id,
          data: {
            accountId: this.moveToSelectedAccount._id,
            supplierId: this.moveToSelectedAccount.supplierId,
            entityId: this.moveToSelectedAccount.entityId,
            ['values.supplierRef']: this.moveToSelectedAccount.supplierRef,
            ['values.meterPointNumber']: this.moveToSelectedAccount.meterPointNumber,
            ['values.meterSerialNumber']: this.moveToSelectedAccount.meterSerialNumber
          }
        });

        if (update) {
          this.$toasted.success('Moved invoice successfully');
          this.loading.main = false;
          this.modals.move = false;
          this.moveToSelectedAccount = null;
          await this.getInvoicesByAccount({ id: this.$route.params.accountId, data: { params: { $deleted: true } } });
        }
      } else {
        this.$toasted.error('Please select an Account');
      }
    },
    async onUpdateAccount() {
      await this.updateAccount({ id: this.selectedAccount._id, data: this.form });

      if (!this.accountError.update) {
        this.$toasted.success('Updated account successfully');
      }
    },
    downloadInvoice(type, downloadUrls = this.modals.pdf.downloadUrls, fileName = this.modals.pdf.fileName) {
      const link = document.createElement('a');
      link.target = '_blank';
      link.href = type ? downloadUrls[type] : downloadUrls.url || downloadUrls.stampedUrl;
      link.download = fileName;
      link.click();
      URL.revokeObjectURL(link.href);
      this.modals.pdf = false;
    },
    async onClickDownloadInvoice(invoice) {
      invoice.account = this.selectedAccount;
      invoice.account.asset = this.asset;

      const rawFileName = `${friendlyInvoiceName(invoice, this.selectedAccount, this.asset)}.pdf`;
      const fileName = slugify(rawFileName, {
        replacement: ' ',
        lower: false
      });

      const downloadUrls = await this.getDownloadUrls({ id: invoice._id, name: fileName });

      // if (downloadUrls.status) {
      //   this.$toasted.error('Error: Unable to download the invoice files', { position: 'bottom-center', duration: 3000 });
      //   return;
      // }

      this.modals.pdf = { downloadUrls, fileName };
    },
    async onClickDeleteInvoice(invoice) {
      this.modals.remove = invoice;
    },
    async onDeleteInvoice() {
      const updatedAccount = await this.deleteInvoice({ id: this.modals.remove._id });

      if (this.accountError.deleteInvoice) {
        this.$toasted.success('Deleted invoice successfully.', { position: 'bottom-center', duration: 3000 });
      } else {
        this.selectedAccount.invoices = updatedAccount.invoices;
      }

      this.modals.remove = false;
    },
    async onClickRestoreInvoice(invoice) {
      const updatedAccount = await this.restoreInvoice({ id: invoice._id });

      if (this.accountError.restoreInvoice) {
        this.$toasted.success('Restored invoice successfully.', { position: 'bottom-center', duration: 3000 });
      } else {
        this.selectedAccount.invoices = updatedAccount.invoices;
      }
    },
    async onClickPaid(invoice, isChecked) {
      this.$set(this.loading.paid, invoice._id, true);
      await this.updateInvoice({ id: invoice._id, data: { isPaid: isChecked } });
      this.$set(this.loading.paid, invoice._id, false);
    },
    async onClickMoveToCreditNote() {
      const update = await this.convertInvoiceToCreditNote({ id: this.modals.creditNote.accountId, invoiceId: this.modals.creditNote._id });
      if (update) {
        this.$toasted.success('Invoice moved to credit notes.', { position: 'bottom-center', duration: 3000 });
        this.getInvoicesByAccount({ id: this.$route.params.accountId, data: { params: { $deleted: true } } });
      } else {
        this.$toasted.error('Could not move invoice to credit notes.', { position: 'bottom-center', duration: 3000 });
      }
      this.modals.creditNote = false;
    },
    onInvoiceChange(updatedInvoice) {
      this.manualUpdateInvoice(updatedInvoice);
    }
  }
};
</script>
<style lang="scss" scoped>
.mpan {
  font-size: 16px;
  text-align: center;
  .mpan-row {
    width: 100%;
    display: inline-block;
  }
  .mpan-col-2 {
    display: inline-block;
    width: 16.66%;
  }
  .mpan-col-3 {
    display: inline-block;
    width: 25%;
  }
  .mpan-col-4 {
    display: inline-block;
    width: 33.33%;
  }
  .mpan-col-6 {
    display: inline-block;
    width: 50%;
  }
  .box {
    padding: 7px 0;
  }
  .s {
    padding-top: 3px;
    font-size: 48px;
  }
  input {
    border: 0;
    width: 100%;
    text-align: center;
    color: #495057;
  }
}

.is-changed {
  color: #da4c4c !important;
  text-decoration: line-through;
  font-weight: 600;
}

.is-simulated {
  background-color: #fff7e9 !important;
}
</style>
